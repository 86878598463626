<template>
	<div>
		<Header :childIndex="0" ref="header"></Header>
		<div class="main_box">
			<div class="main_boxc">
				<div class="title_box">
					<router-link :to="{path:'/'}">
						<p>首页 ></p>
					</router-link>
					<span>搜索列表</span>
				</div>
				<div class="list_box">
					<div class="list_box_l">
						<div class="list_class">
							<div class="list_class_l">
								<ul>
									<li :class="curClassType == value?'list_class_cur':''" @click="changeClass(value)" v-for="value in classTypeOptions" :key="value">{{value == 0?'词条':value == 1?'文章':value == 2?'图书':value == 3?'图片':value == 4?'音频':value == 5?'视频':''}}</li>
								</ul>
							</div>
							<div class="list_class_r">
								<input type="text" v-model="searchWord" />
								<img @click="toSearch()" src="../../../images/search_ico4.png" />
							</div>
						</div>
						<div v-if="mainList.length > 0">
							<div class="list_box_con">
								<ul>
									<li v-for="(value,index) in mainList" :key="index">
										<!-- 词条 -->
										<div class="element_box" v-if="curClassType == 0">
											<div class="element_box_l" @click="toElementDetail(value.id)">
												<img :src="$ImgUrl + value.coverPath" :onerror="$parent.defaultImg1" />
											</div>
											<div class="element_box_r">
												<h3 @click="toElementDetail(value.id)" v-html="value.name"></h3>
												<p v-html="$options.filters.GetSubstring(value.introduction, 80)"></p>
											</div>
										</div>
										<!-- 文章 -->
										<div class="entrylist_box" v-if="curClassType == 1">
											<h3 @click="toEntryDetail(value.id)" v-html="value.name"></h3>
											<p v-html="$options.filters.GetSubstring(value.introduction, 110)"></p>
										</div>
										<!-- 图片 -->
										<div class="ImgList_box" v-if="curClassType == 3">
											<div class="ImgList_box_l" @click="toImageDetail(value.id)">
												<img :src="$ImgUrl + value.coverPath" :onerror="$parent.defaultImg1" />
											</div>
											<div class="ImgList_box_r">
												<h3 @click="toImageDetail(value.id)" v-html="value.name"></h3>
												<div class="ImgList_box_r1">
													<span>图片尺寸：1225*548</span>
													<span>图片格式：jpg</span>
												</div>
												<p v-if="value.resName">图片来源：{{value.resName}}</p>
											</div>
										</div>
										<!-- 图书 -->
										<div class="book_box" v-if="curClassType == 2">
											<div class="book_box_l" @click="toBookDetail(value.id)">
												<img :src="$ImgUrl + value.coverPath" :onerror="$parent.defaultImg1" />
											</div>
											<div class="ImgList_box_r book_box_r">
												<h3 @click="toBookDetail(value.id)" v-html="value.name"></h3>
												<div class="ImgList_box_r1">
													<span>{{value.author}}</span>
													<span v-if="value.iSBN">ISBN：{{value.iSBN}}</span>
												</div>
												<p v-if="value.publisher">出版社：{{value.publisher}}</p>
											</div>
										</div>
										<!-- 音频 -->
										<div class="audio_box" v-if="curClassType == 4">
											<div class="audio_box_l" @click="playMedia(value.id)">
												<img v-if="!value.coverPath" src="../../../images/audio_cover.png" />
												<img v-if="value.coverPath" :src="$ImgUrl + value.coverPath" />
											</div>
											<div class="audio_box_r">
												<h3 @click="playMedia(value.id)" :title="value.name" v-html="value.name"></h3>
												<span>{{value.duration}}</span>
												<p :title="value.introduction" v-html="$options.filters.GetSubstring(value.introduction, 200)"></p>
											</div>
										</div>
										<!-- 视频 -->
										<div class="video_box" v-if="curClassType == 5">
											<div class="video_box_l" @click="playMedia(value.id)">
												<img :src="$ImgUrl + value.coverPath" :onerror="$parent.defaultImg1" />
											</div>
											<div class="audio_box_r video_box_r">
												<h3 @click="playMedia(value.id)" :title="value.name" v-html="value.name"></h3>
												<span>{{value.duration}}</span>
												<span>{{value.resExpand}}</span>
												<p :title="value.introduction" v-html="$options.filters.GetSubstring(value.introduction, 200)"></p>
											</div>
										</div>
									</li>
								</ul>
								<div class="news_page">
									<page :page-index="pageIndex" :total="pageCount" :page-size="pageSize" @change="pageChange"></page>
								</div>
							</div>
						</div>
						<NoData v-if="mainList.length == 0"></NoData>
					</div>
					<div class="news_boxr list_box_r">
						<div class="news_boxr1 m_b22">
							<div class="listtitle_box">
								<i></i>
								<img src="../../../images/rsgjc_ico.png">
							</div>
							<div class="zstp_box">
								<a v-for="(value,index) in SearchWordsList" :key="index">{{value.SearchKey}}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
		<div class="video_player" v-show="showPlayer">
			<div class="video_player_c" :style="curClassType == 5?'width: 1200px':'width: 400px;'">
				<div class="dltc_box1_maint">
					<h4>播放</h4>
					<button class="Pop_btn" style="top: 5px;" @click="hidePlayer">
						<img src="../../../images/ts_close.png" />
					</button>
				</div>
				<div class="index_audio" v-if="curClassType == 4">
					<div class="index_audioc">
						<div class="index_audioc_l">
							<img v-if="!audioInfo.CoverPath" src="../../../images/audio_cover.png"/>
							<img v-if="audioInfo.CoverPath" :src="$ImgUrl + audioInfo.CoverPath"/>
						</div>
						<div class="index_audioc_r">
							<h3 :title="audioInfo.ResName">{{audioInfo.ResName}}</h3>
							<span v-if="audioInfo.Time">时长：{{audioInfo.Time}}</span>
						</div>
					</div>
					<audio ref="player" controlsList="nodownload" :src="$ImgUrl + mediaFilePath" controls="controls"></audio>
				</div>
				<div v-if="curClassType == 5">
					<div class="play_layerc1" v-if="mediaFilePath && mediaFilePath.indexOf('<iframe') == -1">
						<video ref="videoPlayer" autoplay="autoplay" :src="$ImgUrl + mediaFilePath" controls="controls" controlsList="nodownload"></video>
					</div>
					<div class="play_layerc1" v-if="mediaFilePath && mediaFilePath.indexOf('<iframe') != -1" v-html="mediaFilePath"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./SearchResult.js">
</script>

<style scoped="scoped">
	.zstp_box{ overflow: hidden;}
	.zstp_box a{color: #504027; font-weight: bold; text-decoration: none; padding: 3px 6px;}
	.audio_cover{ border: 2px solid #eaddd4; width: 100%; height: 126px; box-sizing: border-box; text-align: center; line-height: 122px;}
	.audio_cover img{ width: auto !important; height: auto !important; border: none; vertical-align: middle; max-width: 100%; max-height: 100%;}
	.video_player{ position: fixed; z-index: 1000000; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); display: flex; justify-content: space-around; align-items: center;}
	.video_player_c{ width: 400px; min-height: 160px; background: #fff; box-sizing: border-box; overflow: hidden;}
	.video_player_c .dltc_box1_maint{ width: 100%;}
	.video_player_c audio{ margin: 50px 40px 0; width: calc( 100% - 80px ); min-height: 30px; display: block !important; outline: none;}
	.play_layerc1{ float: left; width: 100%; padding: 18px; box-sizing: border-box;}
	.play_layerc1 video{ float: left; width: 100%; height: 500px; outline: none;}
	.play_layerc1 iframe{ width: 100%; height: 500px;}
</style>
